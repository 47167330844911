import { useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import SortableList from "../../Common/Components/Lists/SortableList";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {Col, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {ADMIN_PATHS} from "../../config";
import AdminResponseHonorarium from "../Models/Response/AdminResponseHonorarium";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import {UrlStructure} from "../../AppSystem/Helpers/UrlStructure";

const AdminHonorariumList = () => {
    const isSuperAdmin = CheckUserRole("SuperAdmin");
    const { t } = useTranslation();
    const navigate = useNavigate();

    let permission = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.honorarium") || CheckUserPermission("admin.honorarium.create")) {
        permission = true;
    }

    const renderActionColumn = (item) => {
        return (
            <>
                <CustomButton icon = "bx bx-show py-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded" buttonBorder={false} id={item.id} onClick={() => {
                                  navigate(`${ADMIN_PATHS.HONORARIUM_INFO}?id=${item.id}`);
                              }} />
            </>
        );
    }

    const renderButtonColumn = (item) => {
        return (
            <>
                {permission ?
                    <Col lg={12} className="mt-4 px-3">
                        <CustomButton customClasses="rounded" text={t('honorarium.newHonorarium')} icon="bx bx-plus pe-2" onClick={() => {
                            navigate(ADMIN_PATHS.HONORARIUM)
                        }}
                        />
                    </Col>

                    : ""}
            </>
        );
    }

    const columns = [
        "visualId",
        'createdAt',
        "userName",
        "orgName",
        "costPlace",
        'firstPayment',
        'paymentInterval',
        {
            "field" : "annualHonorarium",
            "label" : t('honorarium.annual'),
            'insideClass' : (item)=> item.annualHonorarium >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width',//w-50
            "value": (item) =>  item.annualHonorarium ? ToCustomDecimal(item.annualHonorarium) + ' kr' : "",
            "type": "number"
        },
        "status"
    ];

    const columnsToExport = ["visualId", 'createdAt', "userName","orgName", "costPlace",  'firstPayment', 'paymentInterval', "annualHonorarium", "status"];

    if (isSuperAdmin === true) {
        columns.unshift('id');
    }
    const listOptions = {
        "model": AdminResponseHonorarium,
        "columns": columns,
        "actions": {
            "actionColumn" : renderActionColumn,
            "addButton": renderButtonColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/honorarium/admin/search/filter",
        "nameFile": t('honorarium.honorarium'),
        "ignoreFields" : columnsToExport,
        "modelType" : "Honorarium",
        "filterTestIsAvailable": true,
        "urlGetSearch" : "/honorarium/admin/search/filter",

    };

    return (
        <Col lg="12" className='card card-backend p-4 mb-5'>
            <h2 className="pb-2 ps-1 rokkitt-font">{t('honorarium.honorarium')}</h2>
            <SortableList
                listOptions={listOptions}
            />
        </Col>
    );
};

export default AdminHonorariumList;